@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@font-face {
	font-family: 'Poppins';
	src: local('Poppins Regular'),
		url('./assets/fonts/Poppins-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: 'Poppins';
	src: local('Poppins Medium'),
		url('./assets/fonts/Poppins-Medium.woff') format('woff');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins SemiBold'),
		url('./assets/fonts/Poppins-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins Bold'),
		url('./assets/fonts/Poppins-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Outfit';
	src: local('Outfit'), url('./assets/fonts/OutfitBold.woff') format('woff'),
		url('./assets/fonts/OutfitExtraBold.woff') format('woff'),
		url('./assets/fonts/OutfitBlack.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Outfit';
	src: local('Poppins SemiBold'),
		url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),
		local('Poppins Bold');
	unicode-range: U+2c, U+3b;
	font-display: swap;
}

@layer base {
	body {
		overflow-anchor: none;
	}
	html {
		@apply scroll-smooth font-body text-dark-75 leading-6 text-base;
	}

	/* Containers */
	section {
		@apply py-32 px-12 xl:py-40 xl:px-44 snap-start;
	}

	/* Typography */
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-heading;
	}

	h1,
	h2 {
		@apply font-extrabold text-[2.5rem] leading-[2.75rem] md:leading-[3.5rem] md:text-[3.5rem] xl:leading-[4rem] xl:text-[4rem];
	}

	h3 {
		@apply font-bold text-3xl md:text-4xl xl:text-5xl;
	}

	h4 {
		@apply font-bold text-[2rem] leading-10
		md:text-4xl;
	}

	h5 {
		@apply font-black text-[1.75rem] leading-8
		md:text-4xl md:leading-9;
	}

	h6 {
		@apply font-black text-2xl leading-6 md:leading-8;
	}

	button {
		@apply font-body rounded-[3px] uppercase;
	}

	input[type='date'] {
		@apply relative;
	}

	input[type='date']::-webkit-calendar-picker-indicator {
		@apply w-full h-full bg-none text-transparent absolute inset-0 cursor-pointer;
	}

	input[value='']::-webkit-datetime-edit-text,
	input[value='']::-webkit-datetime-edit-month-field,
	input[value='']::-webkit-datetime-edit-day-field,
	input[value='']::-webkit-datetime-edit-year-field {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: none;
	}
}

@layer components {
	/* For components e.g. btn-blue for buttons */
	.slide {
		@apply absolute w-full h-full bg-cover bg-no-repeat overflow-hidden transition duration-1000;
	}

	.slide-text {
		@apply absolute right-0 py-5 px-4 rounded-none sm:rounded-l-sm
		bg-dark-100 w-full sm:w-fit text-light uppercase;
	}

	.pattern-divider {
		@apply bg-pattern bg-maroonSecondary h-[120px] bg-[length:120px_120px];
	}

	.section-container {
		@apply h-full flex flex-col items-center justify-center bg-light;
	}

	.section-content {
		@apply text-xl leading-8 mt-16 max-w-[1096px] flex flex-col gap-16 mx-auto;
	}

	.room-card-btn {
		@apply mt-0.5 w-full py-3 font-black text-2xl bg-light text-maroonPrimary border border-dark-25;
	}

	.room-modal-btn {
		@apply mt-0.5 w-full py-3 font-black text-2xl bg-maroonPrimary text-light;
	}

	.review-container {
		@apply flex flex-col gap-6 w-full;
	}

	.review-card {
		@apply inline-flex p-8 gap-6 flex-col justify-between border border-dark-10 rounded-3xl shadow-sm;
	}

	.form-input {
		@apply flex flex-col gap-y-2;
	}

	.book-submit-btn {
		@apply my-6 py-5 px-4 w-full font-black text-xl bg-maroonPrimary text-light rounded-[3px];
	}

	ol {
		list-style: decimal;
		counter-reset: item;
	}

	ol > li:before {
		content: counters(item, '.') ' ';
		counter-increment: item;
		@apply font-bold leading-6 md:leading-8;
	}

	ol.main-list {
		@apply flex flex-col gap-12 divide-y border-t;
	}

	li.main-list-item {
		@apply flex flex-col gap-6 py-6;
	}

	li.main-list-item:has(> h6):before {
		content: '';
	}
	.sub-list {
		@apply flex flex-col gap-6;
	}

	li.sub-list-item {
		@apply block;
	}
	li.sub-list-item ul,
	li.sub-list-item p {
		@apply py-3;
	}
	li.sub-list-item > strong {
		@apply inline-block p-3 break-words;
	}
	li.sub-list-item > ul,
	ul.sub-list {
		@apply px-8 list-disc flex flex-col gap-3;
	}
}

@media screen and (min-width: 1920px) {
	html {
		@apply text-2xl;
	}

	.section-content {
		@apply max-w-[80%];
	}

	#features .section-content div div {
		@apply 2xl:w-[300px];
	}
}

@media print {
	nav#navbar,
	footer#footer {
		display: none;
	}
}
